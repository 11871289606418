module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:500,700"]}},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"name":"light","bgColor":"#fff","bgColorSecondary":"#F7F2ED","primaryColor":"#2d728f","secondaryColor":"#f49e4c","terciaryColor":"#ab3428","quaternaryColor":"#3b8ea5","quinaryColor":"#f5ee9e","lightBgColor":"#fff","lightPrimaryColor":"#2d728f"},"dark":{"name":"dark","bgColor":"#1e1e1e","bgColorSecondary":"#2d2d2d","primaryColor":"#cccccc","secondaryColor":"#f49e4c","terciaryColor":"#ab3428","quaternaryColor":"#3b8ea5","quinaryColor":"#f5ee9e","lightBgColor":"#fff","lightPrimaryColor":"#2d728f"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b3e9ac3f49be0169b44f077aa69db4b7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-E6KB9YT97R"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":20,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
